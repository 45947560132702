// Header

.header {
	.navbar {
		padding: 0.625rem 1.5rem;
		#nav-toggle {
			font-size: 20px;
			color: var(--#{$prefix}gray-700);
			line-height: 0.5;
		}
		.navbar-nav {
			@media (max-width: 576px) {
				flex-direction: row;
			}
			@media (min-width: 576px) and (max-width: 767.98px) {
				flex-direction: row;
			}
			@media (min-width: 768px) and (max-width: 991.98px) {
				flex-direction: row;
			}
		}
	}
}

// Layouts

// Dashboard wrapper

#db-wrapper {
	overflow-x: hidden;
	display: flex;
}

// Dashboard page content

#page-content {
	min-height: 100vh;
	min-width: 100vw;
	margin-left: 15.625rem;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
}

#page-content-for-mini {
	min-height: 100vh;
	min-width: 100vw;
	margin-left: 75px;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
}
// Dashboard navbar vertical toggled

#db-wrapper.toggled .navbar-vertical {
	margin-left: 0;
}

#db-wrapper.toggled .navbar-vertical-compact {
	margin-left: 0;
}

// Dashboard page content toggled

#db-wrapper.toggled #page-content {
	margin-left: 0;
}

#db-wrapper.toggled #page-content-for-mini {
	margin-left: 0;
}

// Media query for layout

@media (max-width: 576px) {
	#page-content {
		margin-left: 0rem;
	}

	#db-wrapper.toggled {
		#page-content {
			margin-left: 15.6875rem;
		}
	}
	#page-content-for-mini {
		margin-left: 0px;
	}

	#db-wrapper.toggled {
		#page-content-for-mini {
			margin-left: 74px;
		}
	}
}

@media (min-width: 768px) {
	.navbar-vertical {
		margin-left: 0rem;
	}
	#page-content {
		min-width: 0rem;
		width: 100%;
	}
	#db-wrapper.toggled .navbar-vertical {
		margin-left: -16rem;
	}
	.navbar-vertical-compact {
		margin-left: 0px;
	}
	#page-content-for-mini {
		min-width: 0rem;
		width: 100%;
	}
	#db-wrapper.toggled .navbar-vertical-compact {
		margin-left: -74px;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	#page-content {
		margin-left: 0rem;
	}
	#db-wrapper.toggled {
		#page-content {
			margin-left: 15.6875rem;
		}
	}
	#page-content-for-mini {
		margin-left: 0rem;
	}
	#db-wrapper.toggled {
		#page-content {
			margin-left: 74px;
		}
	}
}
