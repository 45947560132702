// Tiny slider

.controls {
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	left: 0;
	font-size: 18px;
	width: 100%;
	top: 50%;
	transform: translatey(-50%);
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 768px) {
		position: absolute;
		bottom: -50px;
		justify-content: center;
		top: inherit;
	}
	&:focus {
		outline: none;
	}
	li {
		cursor: pointer;
		&.prev {
			position: relative;
			background-color: var(--#{$prefix}white);
			height: 40px;
			width: 40px;
			margin-left: -50px;
			border-radius: 50%;
			line-height: 40px;
			text-align: center;
			border: 1px solid var(--#{$prefix}border-color);
			transition: 0.2s ease-in;
			color: var(--#{$prefix}gray-400);
			@media (max-width: 768px) {
				margin-right: 5px;
			}
			&:hover {
				background-color: var(--#{$prefix}primary);
				color: var(--#{$prefix}white);
				border-color: var(--#{$prefix}primary);
			}
		}
		&.next {
			position: relative;
			background-color: var(--#{$prefix}white);
			height: 40px;
			width: 40px;
			margin-right: -50px;
			border-radius: 50%;
			line-height: 40px;
			text-align: center;
			border: 1px solid var(--#{$prefix}border-color);
			transition: 0.2s ease-in;
			color: var(--#{$prefix}gray-400);
			@media (max-width: 768px) {
				margin-left: 5px;
			}
			&:hover {
				background-color: var(--#{$prefix}primary);
				color: var(--#{$prefix}white);
				border-color: var(--#{$prefix}primary);
			}
		}
	}
}
.controls-testimonial {
	position: absolute;
	bottom: -100px;
	@media (max-width: 990px) {
		bottom: -50px;
	}
	justify-content: center;
	top: inherit;
}

// Ecommerce product tools

.product-tools {
	position: relative;
	.thumbnails {
		margin: 10px 0;
		text-align: center;
	}
	img {
		border-radius: 6px;
		max-width: 100%;
	}
}

.product {
	.tns-item {
		Image {
			border-radius: 6px;
			width: 100%;
		}
	}
}

.thumbnails {
	li {
		Image {
			height: auto;
		}
	}
	.tns-nav-active {
		.thumbnails-img {
			background: none;
			border: 2px solid var(--#{$prefix}primary);
			border-radius: 0.5rem;
		}
	}
}
