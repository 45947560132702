// Prism

code[class*='language-'],
pre[class*='language-'] {
	color: var(--#{$prefix}gray-900);
	background: none;
	font-family: $font-family-sans-serif;
	font-size: 0.875rem;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.6;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
pre[class*='language-']::-moz-selection,
pre[class*='language-'] ::-moz-selection,
code[class*='language-']::-moz-selection,
code[class*='language-'] ::-moz-selection {
	text-shadow: none;
	background: #b3d4fc;
}
pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
	text-shadow: none;
	background: #b3d4fc;
}
@media print {
	code[class*='language-'],
	pre[class*='language-'] {
		text-shadow: none;
	}
}

/* Code blocks */

pre[class*='language-'] {
	padding: 1em;
	overflow: auto;
	margin-bottom: 0px;
	border-radius: 0.5rem !important;
}
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
	background: var(--#{$prefix}gray-100);
}

/* Inline code */

:not(pre) > code[class*='language-'] {
	padding: 0.1em;
	border-radius: 0.3em;
	white-space: normal;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: slategray;
}
.token.punctuation {
	color: #999;
}
.token.namespace {
	opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
	color: #905;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #690;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
	color: #9a6e3a;
	background: hsla(0, 0%, 100%, 0.5);
}
.token.atrule,
.token.attr-value,
.token.keyword {
	color: #07a;
}
.token.function,
.token.class-name {
	color: #dd4a68;
}
.token.regex,
.token.important,
.token.variable {
	color: #e90;
}
.token.important,
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}
.token.entity {
	cursor: help;
}

.react-code pre {
	background-color: #18113c !important;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
	color: #df86b4;
	background: none !important;
}
