/** slick-theme.scss */
.slick-prev {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
	left: -25px;
	&:hover {
		color: transparent;
		outline: none;
		background: transparent;
		&:before {
			opacity: 1;
		}
	}
	&:focus {
		color: transparent;
		outline: none;
		background: transparent;
		&:before {
			opacity: 1;
		}
	}
	&:before {
		font-family: 'slick';
		font-size: 20px;
		line-height: 1;
		opacity: 0.75;
		color: var(--#{$prefix}white);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: '←';
	}
}
.slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
	right: -25px;
	&:hover {
		color: transparent;
		outline: none;
		background: transparent;
		&:before {
			opacity: 1;
		}
	}
	&:focus {
		color: transparent;
		outline: none;
		background: transparent;
		&:before {
			opacity: 1;
		}
	}
	&:before {
		font-family: 'slick';
		font-size: 20px;
		line-height: 1;
		opacity: 0.75;
		color: var(--#{$prefix}white);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: '→';
	}
}
.slick-prev.slick-disabled {
	&:before {
		opacity: 0.25;
	}
}
.slick-next.slick-disabled {
	&:before {
		opacity: 0.25;
	}
}
[dir='rtl'] {
	.slick-prev {
		right: -25px;
		left: auto;
		&:before {
			content: '→';
		}
	}
	.slick-next {
		right: auto;
		left: -25px;
		&:before {
			content: '←';
		}
	}
}
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}
.slick-dots {
	position: absolute;
	bottom: -25px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 20px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: transparent;
			&:hover {
				outline: none;
				&:before {
					opacity: 1;
				}
			}
			&:focus {
				outline: none;
				&:before {
					opacity: 1;
				}
			}
			&:before {
				font-family: 'slick';
				font-size: 6px;
				line-height: 20px;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				content: '•';
				text-align: center;
				opacity: 0.25;
				color: var(--#{$prefix}black);
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
	}
	li.slick-active {
		button {
			&:before {
				opacity: 0.75;
				color: var(--#{$prefix}black);
			}
		}
	}
}
