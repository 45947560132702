// Custom passwword

.password-field {
	&:after {
		content: '';
		display: block;
		height: 6px;
		width: 100%;
		margin-top: 3px;
		border-radius: 0.75rem;
		background-color: var(--#{$prefix}gray-200);
	}
	&.level0:after {
		width: 25%;
		background-color: var(--#{$prefix}danger);
	}
	&.level1:after {
		width: 50%;
		background-color: var(--#{$prefix}warning);
	}
	&.level2:after {
		width: 75%;
		background-color: var(--#{$prefix}warning);
	}
	&.level3:after {
		width: 100%;
		background-color: var(--#{$prefix}success);
	}
	&.level0:after {
		width: 100%;
		background-color: var(--#{$prefix}gray-200);
	}
}
