.yarl__navigation_next,
.yarl__navigation_prev,
.yarl__slide_placeholder {
	display: none;
}
.yarl__slide_image {
	border-radius: 0.5rem;
}
.cursor_pointer {
	cursor: pointer;
}
