//
// Extended from bootstrap
//
// btn play
.btn-play {
	background-color: var(--#{$prefix}white);
	color: var(--#{$prefix}primary);
	transition: 0.2s ease;
	&:hover {
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
		transition: 0.2s ease;
	}
}

// Sizing
.btn-xs {
	padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
	font-size: $input-btn-font-size-xs;
}

// btn icon
.btn-icon {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.92969rem;
	font-weight: 400;
	width: 2.5rem;
	height: 2.5rem;
	padding: 0;
}

.btn-icon.btn-xs {
	font-size: 0.75rem;
	width: 1.53125rem;
	height: 1.53125rem;
}

.btn-icon.btn-sm {
	font-size: 0.875rem;
	width: 2.1875rem;
	height: 2.1875rem;
}

.btn-icon.btn-lg {
	font-size: 1rem;
	width: 3.36875rem;
	height: 3.36875rem;
}

// onclick dropdown active btn icon
.dropdown.active.show .btn-icon {
	background-color: $light-primary;
	color: var(--#{$prefix}primary) !important;
}

// primary hover
.primary-hover {
	&:hover {
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
		border: var(--#{$prefix}primary);
	}
	&:focus {
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
		border: var(--#{$prefix}primary);
		box-shadow: 0 0 0 0.2rem rgba(129, 97, 244, 0.5);
	}
	&.active {
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
		border: var(--#{$prefix}primary);
	}
}

// btn focus none
.btn.btn-focus-none {
	&:focus {
		box-shadow: none;
	}
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
	font-weight: $font-weight-semi-bold;
	color: $btn-link-color;
	text-decoration: underline;

	&:hover {
		color: $btn-link-hover-color;
		text-decoration: underline;
	}

	&:focus {
		text-decoration: underline;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
	}

	// No need for an active state here
}

// btn-dashed

.btn-dashed {
	border-style: dashed !important;

	&:hover {
		border-color: var(--#{$prefix}primary) !important;
		color: var(--#{$prefix}primary) !important;
	}
	&:focus {
		box-shadow: none !important;
	}
}

// btn ghost
.btn-ghost {
	--geeks-btn-font-weight: 600;
	--geeks-btn-color: var(--#{$prefix}gray-400);
	--geeks-btn-bg: transparent;
	--geeks-btn-border-color: transparent;
	--geeks-btn-border-radius: 0.5rem;
	--geeks-btn-hover-color: var(--#{$prefix}gray-600);
	--geeks-btn-hover-bg: var(--#{$prefix}btn-ghost-bg);
	--geeks-btn-hover-border-color: var(--#{$prefix}btn-ghost-bg);
	--geeks-btn-focus-shadow-rgb: var(--bd-gray-300-rgb);
	--geeks-btn-active-color: var(--#{$prefix}btn-hover-color);
	--geeks-btn-active-bg: var(--#{$prefix}btn-ghost-bg);
	--geeks-btn-active-border-color: var(--#{$prefix}btn-ghost-bg);
}

// checkbox icon button for ecommerce
.icon-checked {
	opacity: 0;
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
	.icon-checked {
		opacity: 1;
	}
}
