// Stepper

.stepper {
	.step-trigger {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.5;
		color: var(--#{$prefix}gray-700);
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
		vertical-align: middle;
		user-select: none;
		background-color: transparent;
		border: none;
		border-radius: 0.25rem;
		transition:
			background-color 0.15s ease-out,
			color 0.15s ease-out;
		&:not(:disabled) {
			&:not(.disabled) {
				cursor: pointer;
			}
		}
		&:disabled {
			pointer-events: none;
			opacity: 0.65;
		}
		&:focus {
			color: var(--#{$prefix}primary);
			outline: none;
		}
		&:hover {
			text-decoration: none;
			background-color: transparent;
			color: var(--#{$prefix}primary);
		}
	}
	.step-trigger.disabled {
		pointer-events: none;
		opacity: 0.65;
	}
	.line {
		flex: 1 0 32px;
		min-width: 1px;
		min-height: 1px;
		margin: auto;
		background-color: var(--#{$prefix}border-color);
	}
	.content {
		&:not(.fade) {
			display: none;
		}
	}
	.content.fade {
		visibility: hidden;
		transition-duration: 0.3s;
		transition-property: opacity;
	}
	.content.fade.active {
		visibility: visible;
		opacity: 1;
	}
	.content.active {
		&:not(.fade) {
			display: block;
			visibility: visible;
		}
	}
	.content.dstepper-block {
		display: block;
	}
	&:not(.vertical) {
		.stepper-pane.dstepper-none {
			display: none;
		}
		.content.dstepper-none {
			display: none;
		}
	}
}

.stepper-label {
	display: inline-block;
	margin: 0;
	color: var(--#{$prefix}gray-700);
}

.stepper-header {
	display: flex;
	align-items: center;
	background-color: var(--#{$prefix}white);
	padding: 16px;
	border-radius: 0rem 0rem 0.5rem 0.5rem;
}

.stepper-line {
	flex: 1 0 32px;
	min-width: 1px;
	min-height: 1px;
	margin: auto;
	background-color: var(--#{$prefix}border-color);
}

.stepper-circle {
	display: inline-flex;
	align-content: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	margin: 0.25rem;
	line-height: 2.4;
	color: var(--#{$prefix}gray-600);
	background-color: var(--#{$prefix}gray-300);
	border: 1px solid var(--#{$prefix}border-color);
	border-radius: 50%;
	font-size: 16px;
}

.active {
	.stepper-circle {
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
	}
}
.stepper.vertical {
	display: flex;
	.stepper-header {
		flex-direction: column;
		align-items: stretch;
		margin: 0;
	}
	.stepper-pane {
		display: block;
		&:not(.fade) {
			display: block;
			visibility: hidden;
		}
	}
	.content {
		display: block;
		&:not(.fade) {
			display: block;
			visibility: hidden;
		}
	}
}
.stepper-pane {
	&:not(.fade) {
		display: none;
	}
}

.stepper-pane.fade {
	visibility: hidden;
	transition-duration: 0.3s;
	transition-property: opacity;
}
.stepper-pane.fade.active {
	visibility: visible;
	opacity: 1;
}
.stepper-pane.active {
	&:not(.fade) {
		display: block;
		visibility: visible;
	}
}
.stepper-pane.dstepper-block {
	display: block;
}
.vertical {
	.stepper-pane.fade.dstepper-none {
		visibility: hidden;
	}
	.content.fade.dstepper-none {
		visibility: hidden;
	}
}
@media (max-width: 520px) {
	.stepper {
		.step-trigger {
			flex-direction: column;
			padding: 10px;
		}
	}
	.stepper-header {
		overflow: scroll;
	}
	.stepper-content {
		padding: 0;
	}
}
@media (max-width: 400px) {
	.stepper-line {
		flex-basis: 20px;
	}
	.stepper {
		.line {
			flex-basis: 20px;
		}
	}
}
